import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./Website/Index/Main";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom"; // Import BrowserRouter

const container = document.getElementById("root");
const root = createRoot(container);


// Wrap Main component with Router
const index = () => root.render(
  <Router>  {/* BrowserRouter wraps the Main component */}
    <Main />
  </Router>
);

index();  // Call the index function to render Main

reportWebVitals();

export default index;
